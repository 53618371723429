import { Component, OnInit, Input, HostListener } from "@angular/core";
import { Subscription } from "rxjs";
import { HttpService } from "../../services/http.service";
import * as moment from "moment";

@Component({
  selector: "app-predicted-reds-ambers-per-user",
  templateUrl: "./predicted-reds-ambers-per-user.component.html",
  styleUrls: ["./predicted-reds-ambers-per-user.component.scss"]
})
export class PredictedRedsAmbersPerUserComponent implements OnInit {
  screenHeight: number;
  screenWidth: number;
  timestamp;
  timeString: string;

  viewWidth;
  viewHeight;
  yAxisWidth;
  xAxisHeight;
  chartWidth;
  chartHeight;
  rows: [] = [];
  rowHeight;
  circleRadius;
  xTicks: number[] = [];

  maxValue;

  predictionsData;
  usersData;

  @Input() subtitle;

  predictionsDataSubscription: Subscription;
  usersDataSubscription: Subscription;

  constructor(public dataService: HttpService) {
    this.getScreenSize();
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 1450) {
      this.viewHeight = this.screenHeight * 0.45;
      this.yAxisWidth = 90;
      this.xAxisHeight = 30;
      if (this.screenWidth < 960) {
        this.yAxisWidth = 70;
      }
    } else if (this.screenWidth < 1680) {
      this.viewHeight = this.screenHeight * 0.6;
      this.yAxisWidth = 120;
      this.xAxisHeight = 40;
    } else {
      this.viewHeight = this.screenHeight * 0.65;
      this.yAxisWidth = 150;
      this.xAxisHeight = 50;
    }
    this.viewWidth = this.screenWidth * 0.9;
    this.chartHeight = this.viewHeight - this.xAxisHeight;
    this.chartWidth = this.viewWidth - this.yAxisWidth;
    this.setRowHeight();
  }

  ngOnInit() {
    this.usersDataSubscription = this.dataService
      .getFromFirestore("", "customUserColours")
      .subscribe(result => {
        this.usersData = result[0].data;
        this.usersData.push({ name: "Group", value: "rgb(0,0,0)" });
        this.attachCustomColours();
      });
  }

  ngOnChanges() {
    if (this.predictionsDataSubscription) {
      this.predictionsDataSubscription.unsubscribe();
    }
    this.predictionsDataSubscription = this.dataService
      .getFromFirestore(this.subtitle, "predictedRedsAmbersPer")
      .subscribe(result => {
        this.predictionsData = result[0].data;
        if (this.predictionsData.maxValue) {
          this.maxValue = this.predictionsData.maxValue;
        }
        this.rows = this.predictionsData.predictionsData[0].predictedAmbersByDay;
        this.timestamp = result[0].lastUpdated;
        this.timeString = this.setTimeString();
        this.attachCustomColours();
        this.setRowHeight();
        this.setXTicks();
      });
  }

  private attachCustomColours() {
    if (this.predictionsData && this.usersData) {
      this.predictionsData.predictionsData.forEach(user => {
        const userColour = this.usersData.find(
          colour => colour.name == user.name
        );
        if (userColour) {
          user.colour = userColour.value;
        }
      });
    }
  }

  private setTimeString() {
    let string = "Last Updated ";
    const updatedMoment = moment.unix(this.timestamp.seconds);
    string += updatedMoment.format("DD/MM - HH:mm");
    return string;
  }

  private setRowHeight() {
    if (this.rows.length > 0) {
      this.rowHeight = this.chartHeight / (2 * this.rows.length);
    } else {
      this.rowHeight = this.chartHeight / 2;
    }
    this.circleRadius = Math.min(20, this.rowHeight * 0.45);
  }

  private setXTicks() {
    let tickLength = 0;
    if (this.maxValue <= 11) {
      tickLength = 1;
    } else if (this.maxValue <= 22) {
      tickLength = 2;
    } else if (this.maxValue <= 55) {
      tickLength = 5;
    } else if (this.maxValue <= 110) {
      tickLength = 10;
    } else if (this.maxValue <= 200) {
      tickLength = 20;
    } else if (this.maxValue <= 550) {
      tickLength = 50;
    } else {
      tickLength = 100;
    }
    this.xTicks = [];
    const rightTick = this.maxValue - (this.maxValue % tickLength);
    for (let i = rightTick; i >= 0; i = i - tickLength) {
      this.xTicks.push(i);
    }
  }

  ngOnDestroy() {
    if (this.usersDataSubscription) {
      this.usersDataSubscription.unsubscribe();
    }
    if (this.predictionsDataSubscription) {
      this.predictionsDataSubscription.unsubscribe();
    }
  }
}
