import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-custom-legend",
  templateUrl: "./custom-legend.component.html",
  styleUrls: ["./custom-legend.component.scss"]
})
export class CustomLegendComponent implements OnInit {
  colours = [];
  names = [];

  @Input() customColours;
  @Input() scheme;
  @Input() dataFormat;
  @Input() activeData;
  @Input() totalsDisplayed = false;
  @Input() totalsUnits = "";

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    this.names = [];
    this.colours = [];
    if (this.dataFormat === "multi") {
      if (this.customColours && this.customColours.length > 0) {
        for (let i = 0; i < this.customColours.length; i++) {
          for (
            let j = 0, found = false;
            j < this.activeData[0].series.length && found === false;
            j++
          ) {
            if (
              this.activeData[0].series[j].name === this.customColours[i].name
            ) {
              found = true;
              this.names.push(this.customColours[i].name);
              this.colours.push(this.customColours[i].value);
            }
          }
        }
      } else if (this.scheme && this.scheme.length > 0) {
        for (let i = 0; i < this.activeData[0].series.length; i++) {
          this.names.push(this.activeData[0].series[i].name);
          this.colours.push(this.scheme[i % this.scheme.length]);
        }
      }
    } else if (this.dataFormat === "single") {
      if (this.customColours && this.customColours.length > 0) {
        for (let i = 0; i < this.customColours.length; i++) {
          for (
            let j = 0, found = false;
            j < this.activeData.length && found === false;
            j++
          ) {
            if (this.activeData[j].name === this.customColours[i].name) {
              found = true;
              this.names.push(this.customColours[i].name);
              this.colours.push(this.customColours[i].value);
            }
          }
        }
      } else if (this.scheme && this.scheme.domain.length > 0) {
        for (let i = 0; i < this.activeData.length; i++) {
          this.names.push(this.activeData[i].name);
          this.colours.push(this.scheme.domain[i % this.scheme.domain.length]);
        }
      }
    }
  }
}
