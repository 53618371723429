import { NgModule } from "@angular/core";
import {
  MatButtonModule,
  MatMenuModule,
  MatCardModule,
  MatGridListModule,
  MatDialogModule,
  MatBadgeModule,
  MatSnackBarModule,
  MatToolbarModule,
  MatSidenavModule,
  MatProgressBarModule,
  MatInputModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatExpansionModule,
  MatCheckboxModule,
  MatChipsModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatStepperModule,
  MatOptionModule,
  MatSelectModule,
  MatButtonToggleModule,
  MatSlideToggleModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRippleModule,
  MatProgressSpinnerModule
} from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { CUSTOM_BREAKPOINTS } from "./custom-breakpoint";

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatRippleModule,
    FlexLayoutModule.withConfig(
      { mediaTriggerAutoRestore: true },
      CUSTOM_BREAKPOINTS
    )
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatGridListModule,
    MatDialogModule,
    MatBadgeModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatInputModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatChipsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatStepperModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HttpClientModule,
    MatRippleModule,
    MatProgressSpinnerModule
  ]
})
export class SharedModule {}
