import { BREAKPOINT } from "@angular/flex-layout";

export const CUSTOM_BREAKPOINTS = [
  {
    alias: "xs",
    suffix: "xs",
    mediaQuery: "screen and (max-width: 599px)",
    overlapping: false,
    priority: 1000
  },
  {
    alias: "sm",
    suffix: "sm",
    mediaQuery: "screen and (min-width: 600px) and (max-width: 959px)",
    overlapping: false,
    priority: 900
  },
  {
    alias: "md",
    suffix: "md",
    mediaQuery: "screen and (min-width: 960px) and (max-width: 1449px)",
    overlapping: false,
    priority: 800
  },
  {
    alias: "lg",
    suffix: "lg",
    mediaQuery: "screen and (min-width: 1450px) and (max-width: 1679px)",
    overlapping: false,
    priority: 700
  },
  {
    alias: "xl",
    suffix: "xl",
    mediaQuery: "screen and (min-width: 1680px)",
    overlapping: false,
    priority: 600
  },
  {
    alias: "gt-xs",
    suffix: "gt-xs",
    mediaQuery: "screen and (min-width: 600px)",
    overlapping: true,
    priority: -900
  },
  {
    alias: "gt-sm",
    suffix: "gt-sm",
    mediaQuery: "screen and (min-width: 960px)",
    overlapping: true,
    priority: -800
  },
  {
    alias: "gt-md",
    suffix: "gt-md",
    mediaQuery: "screen and (min-width: 1450px)",
    overlapping: true,
    priority: -700
  },
  {
    alias: "gt-lg",
    suffix: "gt-lg",
    mediaQuery: "screen and (min-width: 1680px)",
    overlapping: true,
    priority: -600
  },
  {
    alias: "lt-sm",
    suffix: "lt-sm",
    mediaQuery: "screen and (max-width: 599px)",
    overlapping: true,
    priority: 950
  },
  {
    alias: "lt-md",
    suffix: "lt-md",
    mediaQuery: "screen and (max-width: 959px)",
    overlapping: true,
    priority: 850
  },
  {
    alias: "lt-lg",
    suffix: "lt-lg",
    mediaQuery: "screen and (max-width: 1449px)",
    overlapping: true,
    priority: 750
  },
  {
    alias: "lt-xl",
    suffix: "lt-xl",
    mediaQuery: "screen and (max-width: 1679px)",
    overlapping: true,
    priority: 650
  }
];
