import { Component } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  constructor(public updates: SwUpdate) {}
  title = "digital-signage";

  ngOnInit() {
    setInterval(() => {
      console.log("checking for updates on interval");
      this.updates.available.subscribe(event => {
        console.log("in updates.available.subscribe");
        this.updates.activateUpdate().then(() => document.location.reload());
      });
    }, 60000);
  }
}
