import { Component, OnInit, Input, HostListener } from "@angular/core";
import { Subscription } from "rxjs";
import { HttpService } from "../../services/http.service";
import * as moment from "moment";

@Component({
  selector: "app-booking-values-per-engineer",
  templateUrl: "./booking-values-per-engineer.component.html",
  styleUrls: ["./booking-values-per-engineer.component.scss"]
})
export class BookingValuesPerEngineerComponent implements OnInit {
  screenHeight: number;
  screenWidth: number;
  chartHeight: number;
  chartWidth: number;
  maxValue: number = 0;
  columnWidth: number = 0;
  bookingsData;
  bookingsDataSubscription: Subscription;
  timestamp;
  timeString: string;
  topOfRed = 250;
  bottomOfYellow = 450;
  topOfYellow = 800;
  bottomOfGreen = 1000;
  colourScheme = {
    domain: [
      "#B9418E",
      "#42DAD0",
      "#E5DCEF",
      "#DF5331",
      "#E0C69E",
      "#F2BA3D",
      "#FF1A47",
      "#93F0A6",
      "#07ABF2",
      "#BF2327"
    ]
  };

  activeData = [];

  @Input() page;
  pageNumber;

  constructor(public dataService: HttpService) {
    this.getScreenSize();
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 1450) {
      this.chartHeight = this.screenHeight * 0.45;
    } else if (this.screenWidth < 1680) {
      this.chartHeight = this.screenHeight * 0.6;
    } else {
      this.chartHeight = this.screenHeight * 0.65;
    }
    this.chartWidth = this.screenWidth * 0.9;
    this.setColumnWidth();
  }

  ngOnInit() {
    this.getBookingsData();
  }

  ngOnChanges() {
    this.getScreenSize();
    this.pageNumber = parseInt(this.page.split(" ")[1]);
    this.getBookingsData();
  }

  private getBookingsData() {
    if (this.bookingsDataSubscription) {
      this.bookingsDataSubscription.unsubscribe();
    }
    this.bookingsDataSubscription = this.dataService
      .getFromFirestore("Engineer", "bookingValuesPer")
      .subscribe(result => {
        this.activeData = [];
        this.bookingsData = result[0].data;
        this.bookingsData = this.bookingsData.slice(
          10 * (this.pageNumber - 1),
          10 * this.pageNumber
        );
        this.bookingsData[0].data.forEach(day => {
          this.activeData.push({
            name: day.date
          });
        });
        this.maxValue = 0;
        this.getMaxValue();
        this.timestamp = result[0].lastUpdated;
        this.timeString = this.setTimeString();
        this.setColumnWidth();
      });
  }

  private getMaxValue() {
    if (this.bookingsData) {
      this.bookingsData.forEach(person => {
        person.data.forEach(date => {
          this.maxValue = Math.max(this.maxValue, date.value);
        });
      });
    }
  }

  private setTimeString() {
    let string = "Last Updated ";
    const updatedMoment = moment.unix(this.timestamp.seconds);
    string += updatedMoment.format("DD/MM - HH:mm");
    return string;
  }

  private setColumnWidth() {
    if (this.bookingsData && this.chartWidth) {
      this.columnWidth = (this.chartWidth - 40) / this.bookingsData.length;
    }
  }
}
