import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from "@angular/core";

@Component({
  selector: "app-custom-y-axis",
  templateUrl: "./custom-y-axis.component.html",
  styleUrls: ["./custom-y-axis.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomYAxisComponent implements OnInit {
  @Input() view;
  @Input() label;
  @Input() maxValue;

  totalHeight: string;
  tickLength: number;
  tickArray: number[] = [];
  tickArrayHeight: string;
  tickArrayMargin: string;
  tickLinesHeight: string;
  tickLinesMargin: string;
  tickLineWidth: string;
  labelPos: string;

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    this.totalHeight = this.view[1].toString() + "px";
    this.labelPos = (this.view[1] / 2 - 20).toString() + "px";
    if (this.maxValue === 0) {
      this.maxValue = 1;
    }
    this.tickLineWidth = (this.view[0] + 20).toString() + "px";
    this.setTickLength();
    this.setTickArray();
    this.setTickArrayHeight();
  }

  private setTickLength() {
    if (this.maxValue <= 11) {
      this.tickLength = 1;
    } else if (this.maxValue <= 22) {
      this.tickLength = 2;
    } else if (this.maxValue <= 55) {
      this.tickLength = 5;
    } else if (this.maxValue <= 110) {
      this.tickLength = 10;
    } else if (this.maxValue <= 200) {
      this.tickLength = 20;
    } else if (this.maxValue <= 550) {
      this.tickLength = 50;
    } else {
      this.tickLength = 100;
    }
  }

  private setTickArray() {
    this.tickArray = [];
    const topTick = this.maxValue - (this.maxValue % this.tickLength);
    for (let i = topTick; i >= 0; i = i - this.tickLength) {
      this.tickArray.push(i);
    }
  }

  private setTickArrayHeight() {
    this.tickArrayHeight =
      (((this.view[1] - 5) * this.tickArray[0]) / this.maxValue).toString() +
      "px";
    this.tickLinesHeight =
      (((this.view[1] - 21) * this.tickArray[0]) / this.maxValue).toString() +
      "px";
    this.tickArrayMargin =
      (
        this.view[1] +
        1 -
        (this.view[1] * this.tickArray[0]) / this.maxValue
      ).toString() + "px";
    this.tickLinesMargin =
      (
        this.view[1] +
        10 -
        (this.view[1] * this.tickArray[0]) / this.maxValue
      ).toString() + "px";
  }
}
