import { Component, OnInit, HostListener, Input } from "@angular/core";
import { HttpService } from "../../services/http.service";
import { Subscription } from "rxjs";
import * as moment from "moment";

@Component({
  selector: "app-jobs-not-completed",
  templateUrl: "./jobs-not-completed.component.html",
  styleUrls: ["./jobs-not-completed.component.scss"]
})
export class JobsNotCompletedComponent implements OnInit {
  screenHeight: number;
  screenWidth: number;
  jobsData;
  jobsDataSubscription: Subscription;
  timestamp;
  timeString: string;
  cardDims: string[] = ["240px", "135px"];
  largest = ["480px", "270px"];
  large = ["320px", "180px"];
  medium = ["240px", "135px"];
  small = ["160px", "90px"];

  constructor(public dataService: HttpService) {
    this.getScreenSize();
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngOnInit() {
    this.getJobsData();
  }

  ngOnChanges() {
    this.getScreenSize();
    this.getJobsData();
  }

  private getJobsData() {
    if (this.jobsDataSubscription) {
      this.jobsDataSubscription.unsubscribe();
    }
    this.jobsDataSubscription = this.dataService
      .getFromFirestore("", "jobsNotCompletedInApp")
      .subscribe(result => {
        this.jobsData = result[0].data;
        this.timestamp = result[0].lastUpdated;
        this.timeString = this.setTimeString();
        this.setCardSizes();
      });
  }

  private setTimeString() {
    let string = "Last Updated ";
    const updatedMoment = moment.unix(this.timestamp.seconds);
    string += updatedMoment.format("DD/MM - HH:mm");
    return string;
  }

  private setCardSizes() {
    if (this.screenHeight && this.screenWidth && this.jobsData) {
      if (
        Math.floor((this.screenWidth - 100) / parseInt(this.largest[0])) *
          Math.floor((this.screenHeight * 0.85) / parseInt(this.largest[1])) >=
        this.jobsData.length
      ) {
        this.cardDims = this.largest;
      } else if (
        Math.floor((this.screenWidth - 100) / parseInt(this.large[0])) *
          Math.floor((this.screenHeight * 0.85) / parseInt(this.large[1])) >=
        this.jobsData.length
      ) {
        this.cardDims = this.large;
      } else if (
        Math.floor((this.screenWidth - 100) / parseInt(this.medium[0])) *
          Math.floor((this.screenHeight * 0.85) / parseInt(this.medium[1])) >=
        this.jobsData.length
      ) {
        this.cardDims = this.medium;
      } else {
        this.cardDims = this.small;
      }
    }
  }

  ngOnDestroy() {
    if (this.jobsDataSubscription) {
      this.jobsDataSubscription.unsubscribe();
    }
  }
}
