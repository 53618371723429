import { Component, OnInit, HostListener } from "@angular/core";

@Component({
  selector: "app-signage-slider",
  templateUrl: "./signage-slider.component.html",
  styleUrls: ["./signage-slider.component.scss"]
})
export class SignageSliderComponent implements OnInit {
  @HostListener("document:keypress", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "a") {
      this.backOneSlide();
    } else if (event.key === "d") {
      this.forwardOneSlide();
    } else if (event.key === " ") {
      this.handlePausing();
    }
  }
  paused = false;
  groupsOfWork = [
    "Accounts",
    "Administrator",
    "Engineer",
    "Laboratory",
    "Manager",
    "Quality Assurance",
    "Sales Agent",
    "Superuser"
  ];
  slides = [
    {
      title: "Tasks Complete",
      subtitles: this.groupsOfWork
    },
    {
      title: "Tasks Outstanding",
      subtitles: this.groupsOfWork
    },
    {
      title: "Jobs",
      subtitles: ["Main"]
    },
    {
      title: "Jobs Not Complete",
      subtitles: ["Main"]
    },
    {
      title: "Booking Values",
      subtitles: ["Page 1", "Page 2", "Page 3"]
    },
    {
      title: "Predicted KPI Changes",
      subtitles: this.groupsOfWork
    }
  ];
  timerInit: number = 30;
  timer: number;
  timeLeftString: string = "100%";
  titleString: string;
  subtitleString: string;
  previousString: string;
  nextString: string;
  currentTitle: number = 0;
  currentSubtitle: number = 0;
  resetInterval;
  timerInterval;
  pauseTimeout;

  constructor() {}

  ngOnInit() {
    this.initFunction(false);
  }

  ngOnDestroy() {
    if (this.resetInterval) {
      clearInterval(this.resetInterval);
    }
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    if (this.pauseTimeout) {
      clearTimeout(this.pauseTimeout);
    }
  }

  private changeSlide(backwards: boolean) {
    //set the previous slide string before setting current strings
    if (this.currentSubtitle === 0) {
      this.previousString = this.titleString;
    } else {
      this.previousString = this.subtitleString;
    }
    this.titleString = this.slides[this.currentTitle].title;
    this.subtitleString = this.slides[this.currentTitle].subtitles[
      this.currentSubtitle
    ];
    //increment current slide and set next slide string
    this.currentSubtitle++;
    if (
      this.currentSubtitle >= this.slides[this.currentTitle].subtitles.length
    ) {
      this.currentSubtitle = 0;
      this.currentTitle++;
      if (this.currentTitle >= this.slides.length) {
        this.currentTitle = 0;
      }
      this.nextString = this.slides[this.currentTitle].title;
    } else {
      this.nextString = this.slides[this.currentTitle].subtitles[
        this.currentSubtitle
      ];
    }
    if (backwards) {
      this.previousString = this.nextString;
    }
  }

  private initFunction(backwards: boolean) {
    //initial changeSlide to display data as soon as the component loads
    this.changeSlide(backwards);
    if (!this.paused) {
      //setting the timer bar's width
      this.timerInterval = setInterval(() => {
        this.timer -= 0.1;
        let calc = 100 - (this.timer / this.timerInit) * 100;
        this.timeLeftString = calc.toString() + "%";
      }, 100);
      //setting up timed slide changes
      this.resetInterval = setInterval(() => {
        this.timer = this.timerInit;
        this.changeSlide(backwards);
      }, this.timerInit * 1000);
    }
    this.timer = this.timerInit;
    this.timeLeftString = "0%";
  }

  private backOneSlide() {
    for (let i = 0; i < 2; i++) {
      if (this.currentSubtitle === 0) {
        if (this.currentTitle === 0) {
          this.currentTitle = this.slides.length - 1;
        } else {
          this.currentTitle--;
        }
        this.currentSubtitle =
          this.slides[this.currentTitle].subtitles.length - 1;
      } else {
        this.currentSubtitle--;
      }
    }
    if (this.resetInterval) {
      clearInterval(this.resetInterval);
    }
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    if (this.pauseTimeout) {
      clearTimeout(this.pauseTimeout);
    }
    this.initFunction(true);
  }

  private forwardOneSlide() {
    if (this.resetInterval) {
      clearInterval(this.resetInterval);
    }
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    if (this.pauseTimeout) {
      clearTimeout(this.pauseTimeout);
    }
    this.initFunction(false);
  }

  private handlePausing() {
    if (this.paused) {
      this.timerInterval = setInterval(() => {
        this.timer -= 0.1;
        let calc = 100 - (this.timer / this.timerInit) * 100;
        this.timeLeftString = calc.toString() + "%";
      }, 100);
      this.pauseTimeout = setTimeout(() => {
        this.changeSlide(false);
        this.timer = this.timerInit;
        this.resetInterval = setInterval(() => {
          this.timer = this.timerInit;
          this.changeSlide(false);
        }, this.timerInit * 1000);
      }, this.timer * 1000);
      this.paused = false;
    } else {
      if (this.resetInterval) {
        clearInterval(this.resetInterval);
      }
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
      }
      if (this.pauseTimeout) {
        clearTimeout(this.pauseTimeout);
      }
      this.paused = true;
    }
  }
}
