import { Component, OnInit, Input, HostListener } from "@angular/core";
import { Subscription } from "rxjs";
import { HttpService } from "./../../services/http.service";
import * as moment from "moment";

@Component({
  selector: "app-outstanding-tasks-per-group",
  templateUrl: "./outstanding-tasks-per-group.component.html",
  styleUrls: ["./outstanding-tasks-per-group.component.scss"]
})
export class OutstandingTasksPerGroupComponent implements OnInit {
  screenHeight: number;
  screenWidth: number;
  showAxis = true;
  colourScheme = { domain: ["#6ebf6e", "#f2b764", "#e27d79"] };

  outstandingData;
  overdueData;
  usersData;
  timestamp;
  timeString: string;

  userGaugeDims = [560, 560];

  @Input() subtitle;

  outstandingDataSubscription: Subscription;
  overdueDataSubscription: Subscription;
  usersDataSubscription: Subscription;

  constructor(public dataService: HttpService) {
    this.getScreenSize();
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 1450) {
      this.showAxis = false;
      this.userGaugeDims = [this.screenHeight / 2, this.screenHeight / 2];
    } else {
      this.showAxis = true;
      this.userGaugeDims = [
        (this.screenHeight * 5) / 9,
        (this.screenHeight * 5) / 9
      ];
    }
  }

  ngOnInit() {
    this.usersDataSubscription = this.dataService
      .getFromFirestore("", "customUserColours")
      .subscribe(result => {
        this.usersData = result[0].data;
        this.attachColoursToOverdues();
      });
  }

  ngOnChanges() {
    this.getOutstandingTasksData();
    this.getOverdueTasksData();
  }

  ngOnDestroy() {
    //prevent data leaks
    if (this.outstandingDataSubscription) {
      this.outstandingDataSubscription.unsubscribe();
    }
    if (this.overdueDataSubscription) {
      this.overdueDataSubscription.unsubscribe();
    }
    if (this.usersDataSubscription) {
      this.usersDataSubscription.unsubscribe();
    }
  }

  private getOutstandingTasksData() {
    if (this.outstandingDataSubscription) {
      this.outstandingDataSubscription.unsubscribe();
    }
    this.outstandingDataSubscription = this.dataService
      .getFromFirestore(this.subtitle, "outstandingTasksPer")
      .subscribe(result => {
        this.outstandingData = result[0].data;
        this.timestamp = result[0].lastUpdated;
        this.timeString = this.setTimeString();
      });
  }

  private setTimeString() {
    let string = "Last Updated ";
    const updatedMoment = moment.unix(this.timestamp.seconds);
    string += updatedMoment.format("DD/MM - HH:mm");
    return string;
  }

  private getOverdueTasksData() {
    if (this.overdueDataSubscription) {
      this.overdueDataSubscription.unsubscribe();
    }
    this.overdueDataSubscription = this.dataService
      .getFromFirestore(this.subtitle, "overdueTasksPer")
      .subscribe(result => {
        this.overdueData = result[0].data;
        this.attachColoursToOverdues();
      });
  }

  private attachColoursToOverdues() {
    if (this.overdueData && this.usersData) {
      for (let i = 0; i < this.overdueData.length; i++) {
        for (
          let j = 0, found = false;
          j < this.usersData.length && found === false;
          j++
        ) {
          if (
            this.overdueData[i].firstname +
              " " +
              this.overdueData[i].lastname ===
            this.usersData[j].name
          ) {
            found = true;
            this.overdueData[i].value = this.usersData[j].value;
          }
        }
      }
    }
  }
}
