import { Component, OnInit, Input, HostListener } from "@angular/core";
import { Subscription } from "rxjs";
import { HttpService } from "../../services/http.service";
import * as moment from "moment";

@Component({
  selector: "app-system-generated-tasks-per-user",
  templateUrl: "./system-generated-tasks-per-user.component.html",
  styleUrls: ["./system-generated-tasks-per-user.component.scss"]
})
export class SystemGeneratedTasksPerUserComponent implements OnInit {
  screenHeight: number;
  screenWidth: number;
  labels = [];
  totals = [];
  dataType = "multi";
  view = [1700, 550];
  colourScheme = {
    domain: [
      "#B9418E",
      "#42DAD0",
      "#E5DCEF",
      "#DF5331",
      "#E0C69E",
      "#F2BA3D",
      "#FF1A47",
      "#93F0A6",
      "#07ABF2",
      "#BF2327"
    ]
  };
  yAxisLabel = "Tasks Complete";
  yAxisHeight: string;
  barPadding: number;
  showDataLabels = true;
  labelFormat = "value";
  timestamp;
  timeString: string;

  typesData;
  totalData;

  @Input() subtitle;

  typesDataSubscription: Subscription;
  totalDataSubscription: Subscription;
  tasks: any;

  constructor(public dataService: HttpService) {
    this.getScreenSize();
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 1680) {
      this.view = [this.screenWidth * 0.85, this.screenHeight * 0.45];
    } else {
      this.view = [this.screenWidth * 0.85, this.screenHeight * 0.55];
    }
  }

  ngOnInit() {}

  ngOnChanges() {
    this.getScreenSize();
    this.yAxisHeight = (this.view[1] + 10).toString() + "px";
    this.getTaskTypesData();
    this.getTotalTasksData();
  }

  ngOnDestroy() {
    //prevent data leaks
    if (this.typesDataSubscription) {
      this.typesDataSubscription.unsubscribe();
    }
    if (this.totalDataSubscription) {
      this.totalDataSubscription.unsubscribe();
    }
  }

  private getTaskTypesData() {
    if (this.typesDataSubscription) {
      this.typesDataSubscription.unsubscribe();
    }
    this.typesDataSubscription = this.dataService
      .getFromFirestore(this.subtitle, "completedTasksOfTypePer")
      .subscribe(result => {
        this.typesData = result[0].data;
        this.timestamp = result[0].lastUpdated;
        this.timeString = this.setTimeString();
        this.barPadding = (this.typesData.barPadding * this.view[0]) / 1700;
        this.labels = [];
        for (let i = 0; i < this.typesData.result.length; i++) {
          this.labels.push(this.typesData.result[i].series);
        }
        this.setTotals();
      });
  }

  private setTimeString() {
    let string = "Last Updated ";
    const updatedMoment = moment.unix(this.timestamp.seconds);
    string += updatedMoment.format("DD/MM - HH:mm");
    return string;
  }

  private getTotalTasksData() {
    if (this.totalDataSubscription) {
      this.totalDataSubscription.unsubscribe();
    }
    this.totalDataSubscription = this.dataService
      .getFromFirestore(this.subtitle, "totalCompletedTasksPer")
      .subscribe(result => {
        this.totalData = result[0].data;
        this.setTotals();
      });
  }

  private setTotals() {
    if (this.typesData && this.totalData) {
      this.totals = [];
      for (let i = 0; i < this.totalData.result.length; i++) {
        if (
          this.typesData.result.find(
            types => types.name == this.totalData.result[i].email
          )
        ) {
          this.totals.push({ value: this.totalData.result[i].value });
        }
      }
    }
  }
}
