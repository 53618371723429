import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignageSliderComponent } from './modules/signage/components/signage-slider/signage-slider.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: '404', component: NotFoundComponent, pathMatch: 'full' },
  {
    path: '',
    component: SignageSliderComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full'
  },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
