import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from "angularfire2/firestore";
import { Observable } from "rxjs";

interface Data {
  data: {};
  name: string;
  lastUpdated: firebase.firestore.Timestamp;
}

@Injectable()
export class HttpService {
  dataCollection: AngularFirestoreCollection<Data>;
  data: Observable<Data[]>;

  constructor(private afs: AngularFirestore, private http: HttpClient) {}
  public getFromFirestore(group: string, data: string) {
    let documentName = data;
    const groupWords = group.split(" ");
    for (const word of groupWords) {
      documentName += word;
    }
    if (
      group === "Accounts" ||
      group === "Laboratory" ||
      group === "Quality Assurance"
    ) {
      documentName += "User";
    }
    this.dataCollection = this.afs.collection("digital-signage", ref => {
      return ref.where("name", "==", documentName);
    });
    this.data = this.dataCollection.valueChanges();
    return this.data;
  }
}
