import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { AccountingComponent } from './pages/accounting/accounting.component';
import { AdministratorsComponent } from './pages/administrators/administrators.component';
import { DirectorsComponent } from './pages/directors/directors.component';
import { DevelopersComponent } from './pages/developers/developers.component';
import { EngineersComponent } from './pages/engineers/engineers.component';
import { SignageCardComponent } from './components/signage-card/signage-card.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { SignageModule } from './modules/signage/signage.module';
import { HttpService } from './modules/signage/services/http.service';
import { AuthService } from './services/auth.service';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SharedModule } from './modules/shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    AccountingComponent,
    AdministratorsComponent,
    DirectorsComponent,
    DevelopersComponent,
    EngineersComponent,
    SignageCardComponent,
    NotFoundComponent
  ],
  imports: [
    SignageModule,
    BrowserModule,
    AngularFireAuthModule,
    AppRoutingModule,
    SharedModule,
    NoopAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [HttpService, AuthService],
  bootstrap: [AppComponent]
})
export class AppModule {}
