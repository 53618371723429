import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from "@angular/core";

@Component({
  selector: "app-custom-x-axis",
  templateUrl: "./custom-x-axis.component.html",
  styleUrls: ["./custom-x-axis.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomXAxisComponent implements OnInit {
  @Input() names;
  @Input() view;
  @Input() dataType;
  @Input() showDataLabels = false;
  @Input() dataLabels;
  @Input() showTotals = false;
  @Input() totals;
  @Input() labelFormat = "none";
  @Input() maxValue;
  @Input() barPadding;

  barWidth: string;
  halfNameWidth: string;
  viewWidth: string;
  barPaddingWidth: string;
  namesLeft: string;
  namesTop: string;

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    this.namesLeft = (this.view[0] / 2 - 80).toString() + "px";
    this.barPaddingWidth = this.barPadding.toString() + "px";
    let calc = this.view[0];
    calc -= 50;
    calc -= this.barPadding * (this.names.length - 1);
    calc = calc / this.names.length;
    this.barWidth = calc.toString() + "px";
    let viewWidthNum = 0;
    if (this.view[0] < 816) {
      viewWidthNum = this.view[0] - calc - 15;
    } else {
      viewWidthNum = this.view[0] - calc + 20;
    }
    this.viewWidth = viewWidthNum.toString() + "px";
    this.namesTop = (this.view[1] + 75 - viewWidthNum / 2).toString() + "px";
    calc = calc / 2;
    this.halfNameWidth = calc.toString() + "px";
    if (this.showDataLabels && this.dataLabels) {
      this.processDataLabels();
    }
    if (this.showTotals && this.totals) {
      this.processTotals();
    }
  }

  private processDataLabels() {
    if (this.dataType === "multi") {
      for (let i = 0; i < this.dataLabels.length; i++) {
        let total = 0;
        for (let j = 0; j < this.dataLabels[i].length; j++) {
          const { name, value } = this.dataLabels[i][j];
          let calc = total + value / 2;
          calc = ((this.view[1] - 20) * calc) / this.maxValue;
          this.dataLabels[i][j].barHeight =
            ((this.view[1] - 20) * value) / this.maxValue;
          if (this.view[0] < 816) {
            calc += 3;
          } else {
            calc -= 5;
          }
          this.dataLabels[i][j].height = calc.toString() + "px";
          total += value;
          if (this.labelFormat === "value") {
            this.dataLabels[i][j].display = value.toString();
          } else if (this.labelFormat === "name") {
            this.dataLabels[i][j].display = name;
          } else if (this.labelFormat === "both") {
            this.dataLabels[i][j].display = name + ": " + value.toString();
          }
        }
      }
    } else if (this.dataType === "single") {
      for (let i = 0; i < this.dataLabels.length; i++) {
        const { value } = this.dataLabels[i];
        let calc = value / 2;
        calc = ((this.view[1] - 20) * calc) / this.maxValue;
        calc -= 5;
        this.dataLabels[i].barHeight =
          ((this.view[1] - 20) * value) / this.maxValue;
        this.dataLabels[i].height = calc.toString() + "px";
        if (this.labelFormat === "value") {
          this.dataLabels[i].display = value;
        } else if (this.labelFormat === "name") {
          this.dataLabels[i].display = name;
        } else if (this.labelFormat === "both") {
          this.dataLabels[i].display = name + ": " + value.toSting();
        }
      }
    }
  }

  private processTotals() {
    for (let i = 0; i < this.totals.length; i++) {
      let barHeight = 0;
      if (this.maxValue > 0 && this.dataLabels && this.dataLabels[i]) {
        this.dataLabels[i].forEach(series => {
          barHeight += (series.value / this.maxValue) * (this.view[1] - 20);
        });
      }
      this.totals[i].heightString = (barHeight + 10).toString() + "px";
    }
  }
}
