import { Component, OnInit, Input, HostListener } from "@angular/core";
import { Subscription } from "rxjs";
import { HttpService } from "../../services/http.service";
import * as moment from "moment";

@Component({
  selector: "app-jobs",
  templateUrl: "./jobs.component.html",
  styleUrls: ["./jobs.component.scss"]
})
export class JobsComponent implements OnInit {
  screenHeight: number;
  screenWidth: number;
  usersData;
  dataFormat = "single";
  showText = true;
  timestamp;
  timeString: string;

  data;

  @Input() dataSource: string;
  dataSubscription: Subscription;
  source: any;

  constructor(public dataService: HttpService) {
    this.getScreenSize();
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 960) {
      this.showText = false;
    } else {
      this.showText = true;
    }
  }

  ngOnInit() {
    this.dataService
      .getFromFirestore("User", "jobsAddedAndBookedPer")
      .subscribe(result => {
        this.data = result[0].data;
        this.timestamp = result[0].lastUpdated;
        this.timeString = this.setTimeString();
      });
    this.dataService
      .getFromFirestore("", "customUserColours")
      .subscribe(result => {
        this.usersData = result[0].data;
      });
  }

  private setTimeString() {
    let string = "Last Updated ";
    const updatedMoment = moment.unix(this.timestamp.seconds);
    string += updatedMoment.format("DD/MM - HH:mm");
    return string;
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }
}
