import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { OutstandingTasksPerGroupComponent } from "./components/outstanding-tasks-per-group/outstanding-tasks-per-group.component";
import { AdministratorsComponent } from "./pages/administrators/administrators.component";
import { CustomLegendComponent } from "./components/custom-legend/custom-legend.component";
import { JobsComponent } from "./components/jobs/jobs.component";
import { CustomXAxisComponent } from "./components/custom-x-axis/custom-x-axis.component";
import { CustomYAxisComponent } from "./components/custom-y-axis/custom-y-axis.component";
import { SystemGeneratedTasksPerUserComponent } from "./components/system-generated-tasks-per-user/system-generated-tasks-per-user.component";
import { SignageSliderComponent } from "./components/signage-slider/signage-slider.component";
import { CustomGuageChartComponent } from "./components/custom-guage-chart/custom-guage-chart.component";
import { SharedModule } from "../shared/shared.module";
import { JobsNotCompletedComponent } from "./components/jobs-not-completed/jobs-not-completed.component";
import { BookingValuesPerEngineerComponent } from "./components/booking-values-per-engineer/booking-values-per-engineer.component";
import { PredictedRedsAmbersPerUserComponent } from './components/predicted-reds-ambers-per-user/predicted-reds-ambers-per-user.component';

@NgModule({
  declarations: [
    OutstandingTasksPerGroupComponent,
    AdministratorsComponent,
    CustomLegendComponent,
    JobsComponent,
    CustomXAxisComponent,
    CustomYAxisComponent,
    SystemGeneratedTasksPerUserComponent,
    SignageSliderComponent,
    CustomGuageChartComponent,
    JobsNotCompletedComponent,
    BookingValuesPerEngineerComponent,
    PredictedRedsAmbersPerUserComponent
  ],
  imports: [CommonModule, NgxChartsModule, SharedModule],
  exports: [JobsComponent, SignageSliderComponent]
})
export class SignageModule {}
