import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signage-card',
  templateUrl: './signage-card.component.html',
  styleUrls: ['./signage-card.component.scss']
})
export class SignageCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
